import {
  ColumnsToolPanelModule,
  MasterDetailModule,
  SideBarModule,
  ExcelExportModule,
  FiltersToolPanelModule,
  ClipboardModule,
  MenuModule,
  SetFilterModule,
} from 'ag-grid-enterprise'

export default [
  ColumnsToolPanelModule,
  SideBarModule,
  MasterDetailModule,
  MenuModule,
  ClipboardModule,
  FiltersToolPanelModule,
  ExcelExportModule,
  SetFilterModule,
]
