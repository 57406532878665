<template>
  <a-tabs v-model:activeKey="eventInspectorTab" data-cy="documents-section-tabs">
    <a-tab-pane v-if="showInspector" key="detail" :tab="$t('detail')">
      <ag-detail v-if="ability.can('view', 'grid:inspector')" />
      <authorisation401 v-else />
    </a-tab-pane>
    <a-tab-pane key="document" :tab="$t('documents')">
      <documents-panel v-if="ability.can('view', 'document')" />
      <authorisation401 v-else />
    </a-tab-pane>
    <a-tab-pane key="tags" :tab="$t('tags')">
      <document-ag-tags v-if="ability.can('view', 'grid:tags')" />
      <authorisation401 v-else />
    </a-tab-pane>
    <a-tab-pane key="sentences" :tab="$t('sentences')">
      <document-ag-sentences v-if="ability.can('view', 'grid:sentences')" />
      <authorisation401 v-else />
    </a-tab-pane>
    <a-tab-pane key="metadata" :tab="$t('metadata')">
      <document-ag-metadata v-if="ability.can('view', 'grid:meta')" />
      <authorisation401 v-else />
    </a-tab-pane>
  </a-tabs>
</template>

<script setup>
import AgDetail from '@/components/detail/ag-detail/ag-detail.vue'
import Authorisation401 from '@/components/errors/authroisation-401.vue'
import DocumentAgMetadata from '@/components/document-nlp/document-ag-metadata/document-ag-metadata.vue'
import DocumentAgSentences from '@/components/document-nlp/document-ag-sentences/document-ag-sentences.vue'
import DocumentsPanel from '@/components/documents/documents-panel/documents-panel.vue'
import DocumentAgTags from '@/components/document-nlp/document-ag-tags/document-ag-tags.vue'
import { defineProps, onBeforeMount, ref } from 'vue'
import useAbility from '@/services/ability.js'
import useUiStore from '@/store/modules/ui'
import useConfigStore from '@/store/modules/config'

const uiStore = useUiStore()
const configStore = useConfigStore()


defineProps({
  showInspector: {
    default: true,
    type: Boolean,
  },
})

const ability = useAbility()
const eventInspectorTab = ref('document')

onBeforeMount(() => {
  // If there is a FileId or FileNumber in the query string, then default to the documents tab
  if (configStore.appParams.FileId || configStore.appParams.FileNumber) {
    eventInspectorTab.value = 'document'
  } else {
    eventInspectorTab.value = uiStore.state.eventInspectorDefaultTab
  }
})
</script>
<style scoped>
.tabs {
  height: 78vh !important;
}
</style>
