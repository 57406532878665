<template>
  <div data-cy="filter-group-item" class="filter-group--item">
    <div class="filter-block">
      <div class="filter-block--container">
        <div class="filter-block--content">
          <a-dropdown :trigger="['click']" :open="visible" @open-change="handleVisibleChange">
            <div class="filter-block--media n-media">
              <h4
                class="filter-block--title n-txt--truncated-one-line n-m-0 n-txt--c-dark n-txt--semi-bold"
              >
                {{ name }}
              </h4>
              <p
                class="filter-block--condition n-media--ext n-pl-025 n-txt--truncated-one-line n-txt--prevent-breaks n-m-0"
              >
                <template v-if="value">
                  {{ filtersMixin.getFilterTypeByAttr(comparison) }}
                  {{
                    filtersMixin.friendlyPredicateValue(
                      value,
                      filterStore.getFilterByAttr(attr).type,
                    )
                  }}
                  {{ filtersMixin.checkAffix(comparison, filterStore.getFilterByAttr(attr).type) }}
                </template>
                <template v-else> - Click to set value </template>
              </p>
              <div
                id="ember3128"
                class="filter-block--delete"
                data-content="Remove this filter"
                @click="triggerRemove"
              >
                <n-icon class="n-m-0" :icon="['fal', 'times']" />
              </div>
            </div>

            <template #overlay>
              <div class="filters--adder-dd predicate-manager">
                <div v-for="(filter, filterId) of filterTypes" :key="filterId">
                  <a-radio-group v-model:value="comparisonValue" style="width: 100%">
                    <a-radio
                      :value="filter.attr"
                      data-cy="timeline-filters-includes-exclude"
                      class="predicate-manager--options"
                    >
                      <span style="text-transform: capitalize">{{ filter.name }}</span>
                    </a-radio>
                  </a-radio-group>
                  <div
                    v-if="comparison === filter.attr"
                    data-cy="timeline-filters-third-menu"
                    class="predicate-selector"
                  >
                    <div v-if="filter.field === 'input'" class="">
                      <a-input v-model="textValue" style="width: 140px; margin-bottom: 0" />
                      {{
                        filtersMixin.checkAffix(filter.attr, filterStore.getFilterByAttr(attr).type)
                      }}
                    </div>
                    <div v-if="filter.field === 'date'" class="" style="width: 100%">
                      <a-date-picker v-model="dateValue" style="width: 100%" format="DD/MM/YYYY" />
                    </div>
                    <div v-if="filter.field === 'array' && filterItem.options" style="width: 100%">
                      <a-select
                        v-model:value="multiSelectValue"
                        show-search
                        data-cy="timeline-filters-third-menu-options"
                        mode="multiple"
                        :options="filterItem.options.map((item) => ({ value: item.name }))"
                        style="width: 100%"
                        :open="selectVisible"
                        :defaultOpen="true"
                        :autofocus="true"
                        :tabindex="0"
                        @click.stop="handleSelectClick"
                      >
                      </a-select>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </a-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { includes } from 'lodash'
import dayjs from 'dayjs'
import NIcon from '@/components/ui/n-icon/n-icon.vue'
import { ref, computed, watch } from 'vue'
import useFilterStore from '@/store/modules/filter'
import useFiltersMixin from '@/mixins/filters-mixin'

const filterStore = useFilterStore()
const filtersMixin = useFiltersMixin()

const emit = defineEmits(['update', 'delete'])

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  attr: {
    type: String,
    required: true,
  },
  value: {
    type: [Number, String, Array],
    default: '',
  },
  comparison: {
    type: [Number, String],
    required: true,
  },
})

const visible = ref(false)
const selectVisible = ref(false)

watch(
  () => visible.value,
  () => {
    selectVisible.value = false
  },
)

const handleSelectClick = () => {
  selectVisible.value = !selectVisible.value
}

const filterItem = computed(() => {
  return filterStore.getFilterByAttr(props.attr)
})

const comparisonValue = computed({
  set: (value) => emit('update', { comparison: value }),
  get: () => props.comparison,
})

const textValue = computed({
  set: (value) => emit('update', { value: value }),
  get: () => {
    if (!props.value || (typeof props.value === 'object' && props.value.isValid())) {
      return ''
    }
    return props.value
  },
})

const multiSelectValue = computed({
  set: (value) => emit('update', { value: value }),
  get: () => (props.value ? props.value : []),
})

const dateValue = computed({
  set: (value) => emit('update', { value: value }),
  get: () => {
    if (!props.value) {
      return ''
    }
    if (dayjs(props.value).isValid()) {
      return props.value
    }
    return dayjs()
  },
})

const filterTypes = computed(() => {
  let available = []
  const currField = filterStore.getFilterByAttr(props.attr)

  for (let item of filterStore.filterTypeOptions) {
    if (includes(item.type, currField.type)) {
      if (!item.hideFrom || !item.hideFrom.includes(props.attr)) {
        available.push(item)
      }
    }
  }

  return available
})

const handleVisibleChange = (status) => {
  visible.value = status
}

const triggerRemove = () => {
  emit('delete', true)
}
</script>
<style lang="less">
.predicate-manager {
  padding: 24px;
  background-color: white;
  &--options {
    display: block;
    margin-bottom: 12px;
    width: 100%;
  }
}
.predicate-selector {
  margin-top: 12px;
  width: 100%;
  &--name {
    display: block;
  }
}
.filter-block--condition {
  padding-left: 0.25rem !important;
}
</style>
<style scoped>
.n-media {
  display: flex;
}
.n-txt--truncated-one-line {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
</style>
